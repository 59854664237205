import {getConfig, isNotNull} from "./Utils";

var app = getConfig();

export function setStats(parameters){
    var path = "/stats/set";
    var url = app.DKW_URL + path;

    var request = new XMLHttpRequest();
    var complexIdCustomer = "";
    var complexIdDemonstration = "";
    var univers = "";

    if(isNotNull(parameters.complexIdCustomer)){
        complexIdCustomer = parameters.complexIdCustomer;
    }else if(isNotNull(localStorage.getItem("DKC_complexId"))){
        complexIdCustomer = localStorage.getItem("DKC_complexId");
    }

    if(isNotNull(parameters.complexIdDemonstration)){
        complexIdDemonstration = parameters.complexIdDemonstration;
    }else if(isNotNull(localStorage.getItem("DKC_demoId"))){
        complexIdDemonstration = localStorage.getItem("DKC_demoId");
    }

    if(isNotNull(parameters.typeId)){
        univers = parameters.typeId;
    }else if(isNotNull(localStorage.getItem("DKC_univers"))){
        univers = localStorage.getItem("DKC_univers");
    }

    if(isNotNull(complexIdCustomer) && isNotNull(complexIdDemonstration) && isNotNull(univers)){
        parameters.complexIdCustomer = complexIdCustomer;
        parameters.complexIdDemonstration = complexIdDemonstration;
        parameters.typeId = univers;

        //alert(JSON.stringify(parameters));

        var params = "jsonParams=" + JSON.stringify(parameters);
        request.onreadystatechange = function() {
            if (request.readyState === XMLHttpRequest.DONE) {
                if (request.status === 200) {
                    var response = JSON.parse(request.response);
                    if (response.error == true) {
                        app.appContext.showAlert(app.appContext.$t('errorSetStats'),"error");
                        return true;
                    }else{
                        return false;
                    }
                }
            }
        };
        if(parameters.typeId == "2"){
            request.open('POST', url);
        }else{
            request.open('POST', url, false);
        }
        request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

        request.send(params);
    }else{
        app.appContext.showAlert(app.appContext.$t('errorSetStats'),"error");
        return true;
    }

}