<template>
    <div class="cart">
        <Steps :steps="processSteps" :nbrProducts="nbrProducts" :active="1" />
        <SlotSelector disabled/>
        <div class="basket-body">
            <div v-if="nbrProducts < 1" class="no-products" >{{$t('emptyBasket')}}</div>
            <div v-else class="cartProducts">

                <div v-for="cartProduct in fullBasket.basketProducts" :key="cartProduct.id" class="cardContainer">
                    <div style="margin: 0 16px;">
                        <div :id="'id-'+cartProduct.id" class="swiper-container">
                            <div :ref="'product-'+cartProduct.id" class="swiper-wrapper">
                                <div class="swiper-slide" style="align-self: center;padding: 5px;">      
                                    <ProductBloc :product="cartProduct.product" :ref="'product-' + cartProduct.id" fullWidth withBorder @updateQty="updateBasket"/>
                                </div>
                                <div class="swiper-slide" style="width:unset;">
                                    <div class="swipe-delete" @click="deleteClickHandler(cartProduct)" :ref="'delete-'+cartProduct.id">
                                        <i class="icon-poubelle delete-picto icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row basket-footer footer">
            <b-modal centered
                    size="sm"
                    header-text-variant="light"
                    no-close-on-backdrop
                    no-close-on-esc
                    hide-header
                    hide-footer
                    style="padding: 0;"
                    v-model="showDeleteModal">

                <div class="food-custom-modal">
                    <span class="food-custom-modal-header">{{$t("deleteProductModalHeader")}}</span>
                    <span class="food-custom-modal-text">{{$t("deleteProductModalText")}}</span>
                    <div class="delete-modal-actions">
                        <button class="btn nok" @click="showDeleteModal = false; productToDelete = null; selectedSwiper.slideTo(0)">{{$t("deleteProductModalNOkLabel")}}</button>
                        <button class="btn ok" @click="deleteFromBasket(productToDelete)">{{$t("deleteProductModalOkLabel")}}</button>
                    </div>
                </div>

            </b-modal>
            <p class="total-amount">
                Total : <strong v-if="nbrProducts>0">{{formatPrice(fullBasket.totalAmount.toFixed(2))}}</strong>
                <strong v-else>{{formatPrice(0.00)}}</strong>
            </p>
            <span v-if="nbrProducts >= 20" class="offline-basket-text">{{$t('messageMaxProductsScanned')}}</span>
            
            <FooterComponent :key="footerRefreshKey" :isOffline="isOffline"/>
        </div>
    </div>
</template>

<script>
    var basket = require('@/assets/js/Food/Basket');
    var Utils = require("@/assets/js/Utils");
    var Customer = require("@/assets/js/Customer");
    var Stats = require("@/assets/js/Stats");
    import Steps from "@/components/Food/Steps";
    import ProductBloc from "@/components/Food/ProductBloc";
    import FooterComponent from "@/components/Food/FooterComponent";
    import SlotSelector from "@/components/Food/SlotSelector.vue"
    import 'swiper/swiper-bundle.css'
    import { Swiper } from 'swiper'

    export default {
        name: 'Basket',
        props: ['isOffline'],
        components:{
            Steps,
            ProductBloc,
            FooterComponent,
            SlotSelector
        },
        data: function() {
            return{
                fullBasket : {
                    "id": 0,
                    "totalAmount": 0,
                    "products": [],
                    "lockedProducts" :[]
                },
                img_url:"",
                customerEmail : "",
                fromRoute : null,
                processSteps: Utils.getSteps(this.$i18n.t),
                showDeleteModal: false,
                productToDelete: null,
                selectedSwiper: null,
                footerRefreshKey : 'basket-footer' + parseInt(Math.random()*100),
                page: {
                    title: this.$t("pages.basket.title"),
                    description: null,
                    showBackBtn: true,
                    showFilterBtn: false
                }
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fromRoute = from;
            })
        },
        created(){
            this.customerEmail = localStorage.getItem("DKC_customerEmail");
            Utils.setContext(this);
            this.img_url = Utils.getConfig().url_img;
        },
        mounted(){
            console.log(this.isOffline)
            this.fullBasket = this.$parent.basket;
            setTimeout(this.initSwiper, 1000)
        },
        methods:{
            backToScanner: function(){
                this.$parent.closeHandler();
                this.$router.push("/scan");
            },
            incrQty: function(productToAdd) {
                basket.addProductToLocalBasket(productToAdd.product);
                sessionStorage.setItem("DKC_food_suspect_basket", true);
                this.updateBasket();
            },
            deleteFromBasket: function(productToDelete) {
                basket.deleteProductFromLocalBasket(productToDelete);
                sessionStorage.setItem("DKC_food_suspect_basket", true);
                this.showDeleteModal = false;
                this.updateBasket();
            },
            decrQty: function(productToDec) {
                if(productToDec.qty > 1){
                    basket.decreaseProductFromLocalBasket(productToDec.product);
                    sessionStorage.setItem("DKC_food_suspect_basket", true);
                    this.updateBasket();
                }else{
                    this.deleteFromBasket(productToDec)
                }
            },
            emptyBasket: function() {
                this.nbProductDeleted += this.fullBasket.basketProducts.length;
                basket.emptyLocalBasket();
                sessionStorage.setItem("DKC_food_suspect_basket", true);
                this.updateBasket();
            },
            toPaymentMethod(){
                if(this.nbrProducts > 0){
                    this.$router.push('/checkin');
                }
            },
            formatPrice(price) {
                return Utils.formatPrice(price);
            },
            updateBasket(){
                this.$parent.basketChange();
                this.footerRefreshKey = 'basket-footer' + parseInt(Math.random()*100)
                this.fullBasket = this.$parent.basket;
            },
            getLang(){
                return this.$root.$i18n.locale;
            },
            initSwiper(){
                let self = this;
                this.fullBasket.basketProducts.forEach((elmt, id) => {
                    let swiper = new Swiper('#id-' + elmt.id, {
                        initialSlide: 0,
                        resistanceRatio: 0.85,
                        slidesPerView: 'auto',
                        speed: 300,
                        effect: 'fade'
                    })
                    let productElmt = this.$refs['product-' + elmt.id][1];
                    console.log(this.$refs['delete-' + elmt.id])
                    this.$refs['delete-' + elmt.id][0].setAttribute('style', `height: ${productElmt.offsetHeight - 10}px;`)
                    swiper.on('reachEnd', function () {
                        self.selectedSwiper = swiper
                    })
                })
            },
            deleteClickHandler(productToDelete){
                this.productToDelete = productToDelete
                this.showDeleteModal = true
            }
        },
        computed:{
            nbrProducts : function() {
                var count = 0;
                if(this.fullBasket.basketProducts){
                    this.fullBasket.basketProducts.forEach(function(product) {
                        count+=product.qty;
                    });
                    return count
                }
            },
            productWording : function() {
                var word = this.$t('product');
                if(this.nbrProducts > 1 ){
                    word = word + 's';
                }
                return word
            }
        },
        beforeRouteLeave(to, from, next) {
            /* eslint-disable */
            //this.statsBasketModifications();
            next();
        }
    }
</script>
<style>
    .basket-header{
        padding: 100px 16px 10px 16px;
        background: rgb(var(--hot-food-color));
        color: white;
        border-bottom-right-radius: 25px;    
        display: flex;
        align-items: center;
        position: fixed;
        width: 100%;
    }
    .basket-body{
        overflow: scroll;
    }
    .basket-header h3{
        margin: 0 8px;
        font-weight: bold;
    }
    .basket-header span{
        font-size: 1.6rem;
    }
    .basket-header .filter-icon{
        margin-left: auto;
    }
</style>